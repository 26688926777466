import React from 'react';
import Helmet from 'react-helmet';
import { useStaticQuery, graphql } from 'gatsby';

const Head = ({
  pageTitle,
  pageDescription,
}: {
  pageTitle?: string;
  pageDescription?: string;
}) => {
  const { site } = useStaticQuery<{
    site: {
      siteMetadata: {
        title: string;
        description: string;
        siteUrl: string;
        themeColor: string;
      };
    };
  }>(
    graphql`
      query {
        site {
          siteMetadata {
            title
            description
            siteUrl
            themeColor
          }
        }
      }
    `
  );

  const { title, description, siteUrl, themeColor } = site.siteMetadata;

  const pageTitleFull = pageTitle ? `${pageTitle} - ${title}` : title;
  const pageDescriptionFull = pageDescription
    ? `${description} ${pageDescription}`
    : description;
  const canonical = siteUrl;

  return (
    <Helmet defaultTitle={site.siteMetadata.title}>
      <html lang="en" />

      <meta
        httpEquiv="Content-Security-Policy"
        content="upgrade-insecure-requests"
      />

      <meta content="IE=edge" httpEquiv="X-UA-Compatible" />
      <meta
        content="width=device-width,initial-scale=1.0,user-scalable=yes"
        name="viewport"
      />

      <meta content={title} name="apple-mobile-web-app-title" />
      <meta content={pageTitleFull} property="og:title" />
      <meta content={pageTitleFull} name="twitter:title" />
      <title>{pageTitleFull}</title>

      <meta content={pageDescriptionFull} name="description" />
      <meta content={pageDescriptionFull} property="og:description" />
      <meta content={pageDescriptionFull} name="twitter:description" />

      <meta content="yes" name="apple-mobile-web-app-capable" />
      <meta
        content="black-translucent"
        name="apple-mobile-web-app-status-bar-style"
      />
      <meta content={themeColor} name="theme-color" />
      <meta content={title} name="application-name" />

      <meta content="website" property="og:type" />
      <meta content={title} property="og:site_name" />
      <meta content="summary_large_image" name="twitter:card" />
      <meta content={pageTitleFull} name="twitter:text:title" />
      <meta content={canonical} property="og:url" />
      <meta content={canonical} name="twitter:url" />
      <link rel="canonical" href={canonical} />

      <meta content={`${siteUrl}/image.png`} property="og:image" />
      <meta content="1920" property="og:image:width" />
      <meta content="1080" property="og:image:height" />
      <meta content={`${siteUrl}/image.png`} name="twitter:image" />
      <meta content="1920" name="twitter:image:width" />
      <meta content="1080" name="twitter:image:height" />
      <meta content={`${siteUrl}/image.png`} property="og:image" />
      <meta content="1920" property="og:image:width" />
      <meta content="1080" property="og:image:height" />

      <meta content={themeColor} name="msapplication-TileColor" />

      <link href="/manifest.webmanifest" rel="manifest" />
    </Helmet>
  );
};

export default Head;
