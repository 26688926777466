import { createGlobalStyle } from 'styled-components';
import 'assets/fonts/firacode.font.css';

const GlobalStyle = createGlobalStyle`
  body {
    margin: 0;
    padding: 0;
  }

  *,
  *::before,
  *::after {
    box-sizing: border-box;
  }

  :root {
    tab-size: 2;
  }

  html {
    font-family: 'Fira Code', 'Courier New', Courier, monospace;
  }
`;

export const theme = {
  backgrounds: {
    normal: '#252431',
  },
  colors: {
    primary: '#5340fc',
    secondary: '#e3e0fc',
    thirdary: '#a4a1c2',
  },
};

export default GlobalStyle;
