import { ReactNode } from 'react';
import { ThemeProvider } from 'styled-components';

import Head from './Head';
import GlobalStyle, { theme } from './GlobalStyle';

const Layout = ({ children }: { children: ReactNode }) => (
  <>
    <Head />
    <GlobalStyle />
    <ThemeProvider theme={theme}>{children}</ThemeProvider>
  </>
);

export default Layout;
